export const streets = [
    { value: "Aïdastraat", label: "Aïdastraat" },
    { value: "Anklaarseweg", label: "Anklaarseweg" },
    { value: "Bronsgietsdronk", label: "Bronsgietsdronk" },
    { value: "Carmenstraat", label: "Carmenstraat" },
    { value: "Fauststraat", label: "Fauststraat" },
    { value: "Fideliostraat", label: "Fideliostraat" },
    { value: "Figarostraat", label: "Figarostraat" },
    { value: "Laan van Zevenhuizen", label: "Laan van Zevenhuizen" },
    { value: "Lakméstraat", label: "Lakméstraat" },
    { value: "Leonorestraat", label: "Leonorestraat" },
    { value: "Lohengrinstraat", label: "Lohengrinstraat" },
    { value: "Medestraat", label: "Medestraat" },
    { value: "Moerbosch", label: "Moerbosch" },
    { value: "Normastraat", label: "Normastraat" },
    { value: "Operaplein", label: "Operaplein" },
    { value: "Parelvisserstraat", label: "Parelvisserstraat" },
    { value: "Parsivalstraat", label: "Parsivalstraat" },
    { value: "Pascalstraat", label: "Pascalstraat" },
    { value: "Platostraat", label: "Platostraat" },
    { value: "Pythagorasstraat", label: "Pythagorasstraat" },
    { value: "Rigolettostraat", label: "Rigolettostraat" },
    { value: "Rousseaustraat", label: "Rousseaustraat" },
    { value: "Rusalkastraat", label: "Rusalkastraat" },
    { value: "Saloméstraat", label: "Saloméstraat" },
    { value: "Sartresstraat", label: "Sartresstraat" },
    { value: "Senecaststraat", label: "Senecaststraat" },
    { value: "Sluisoordlaan", label: "Sluisoordlaan" },
    { value: "Tannhäuserstraat", label: "Tannhäuserstraat" },
    { value: "Toscastraat", label: "Toscastraat" },
    { value: "Traviatastraat", label: "Traviatastraat" },
    { value: "Troubadourstraat", label: "Troubadourstraat" },
    { value: "Voltairestraat", label: "Voltairestraat" },
    { value: "Zenostraat", label: "Zenostraat" },
];

export const genders = [
    { value: "Man", label: "Man" },
    { value: "Vrouw", label: "Vrouw" },
    { value: "Anders", label: "Anders" },
];

export const arrangements = [
    { value: "Koop", label: "Koop" },
    { value: "Huur", label: "Huur" },
];

export const types = [
    {
        value: "Rijtjeswoning",
        label: "Rijtjeswoning",
    },
    {
        value: "Twee onder één kap",
        label: "Twee onder één kap",
    },
    {
        value: "Vrijstaande woning",
        label: "Vrijstaande woning",
    },
    {
        value: "Appartement/flat",
        label: "Appartement/flat",
    },
    {
        value: "Ouderen/senioren woning",
        label: "Ouderen/senioren woning",
    },
    {
        value: "Anders",
        label: "Anders",
    },
];

export const owners = [
    {
        value: "Ikzelf en/of mijn partner",
        label: "Ikzelf en/of mijn partner",
    },
    {
        value: "De woningbouwcoöperatie",
        label: "De woningbouwcoöperatie",
    },
    {
        value: "Anders",
        label: "Anders",
    },
];

export const ages = [
    { value: "17 of jonger", label: "17 of jonger" },
    { value: "18-20 jaar", label: "18-20 jaar" },
    { value: "21-29 jaar", label: "21-29 jaar" },
    { value: "30-39 jaar", label: "30-39 jaar" },
    { value: "40-49 jaar", label: "40-49 jaar" },
    { value: "50-59 jaar", label: "50-59 jaar" },
    { value: "60-69 jaar", label: "60-69 jaar" },
    { value: "70 of ouder", label: "70 of ouder" },
];

export const samenstellingen = [
    { value: "Eenpersoonshuishouden", label: "Eenpersoonshuishouden" },
    {
        value: "Meerpersoonshuishouden met kinderen",
        label: "Meerpersoonshuishouden met kinderen",
    },
    {
        value: "Meerpersoonshuishouden zonder kinderen",
        label: "Meerpersoonshuishouden zonder kinderen",
    },
];
